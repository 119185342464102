<template>
  <section class="card custom-section">
    <h3 class="mb-3">
      Accrued Interest Schedule (Not Considering Payments, i.e. not Amortization Schedule)
    </h3>
    <form @submit.prevent="fetchSchedule(false)">
      <div>
        <wb-text-field
          v-model="amountInCents"
          label="Amount in Cents"
          type="number"
          required
        />
      </div>
      <div>
        <wb-text-field
          v-model="annualPercentageRate"
          label="Annual Percentage Rate (%)"
          type="number"
          step="0.01"
          required
        />
      </div>
      <wb-date-picker
        class="my-3"
        :show-grouping="false"
        :min="new Date((new Date().setFullYear(new Date().getFullYear() - 5))).toISOString()"
        :max="new Date((new Date().setFullYear(new Date().getFullYear() + 5))).toISOString()"
      />
      <div>
        <wb-select
          v-model="frequency"
          label="Frequency"
          required
          :items="['daily', 'monthly', 'yearly']"
        />
      </div>
      <v-btn
        class="my-3"
        type="submit"
        color="primary"
      >
        Get Schedule
      </v-btn>
    </form>
    <v-btn
      class="mb-3"
      color="primary"
      @click="fetchSchedule(true)"
    >
      Download Schedule
    </v-btn>
    <div v-if="scheduleResult">
      <div>
        <h4>Values used:</h4>
        <h5>Frequency Interest Rate:</h5>
        <p>
          {{ Number(scheduleResult.frequencyInterestRate).toFixed(5) }} -
          {{ Number(scheduleResult.frequencyInterestRate * 100).toFixed(2) }}% {{ frequency }}
        </p>
        <h5>Total period:</h5>
        <p>{{ scheduleResult.totalPeriod }}</p>
        <h5>Total Accrued Interest:</h5>
        <p>{{ scheduleResult.totalAccruedInterestInCents }}</p>
      </div>
      <h4>Schedule Result:</h4>
      <ul>
        <li v-for="statement in scheduleResult.statements" :key="statement.date">
          Date: {{ statement.date }},
          Amount: {{ statement.amountInCents }},
          Accrued Interest: {{ statement.accruedInterestInCents }},
          Total Accrued Interest: {{ statement.totalAccruedInterestInCents }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import WbTextField from '@/components/text_field/index.vue';
import WbSelect from '@/components/select/index.vue';
import WbDatePicker from '@/components/date_picker_adv/index.vue';
import downloadFile from '@/helpers/download_file';
import { store as dateRangeStore } from '@/store/date_range';
import { calculateAccruedInterestScheduleOverPeriod } from '../dependencies';

export default Vue.extend({
  name: 'AccruedInterestSchedule',
  components: {
    WbTextField,
    WbSelect,
    WbDatePicker,
  },
  data() {
    return {
      amountInCents: 0,
      annualPercentageRate: 0,
      startDate: '',
      endDate: '',
      frequency: 'daily',
      scheduleResult: null,
    };
  },
  computed: {
    dateRangeStore() {
      return dateRangeStore;
    },
  },
  methods: {
    async fetchSchedule(download) {
      try {
        const { startDate, endDate } = this.dateRangeStore.dateRange;

        const result = await calculateAccruedInterestScheduleOverPeriod({
          amountInCents: Number(this.amountInCents),
          annualPercentageRate: Number(this.annualPercentageRate),
          startDate,
          endDate,
          frequency: this.frequency,
          download,
        });
        if (download) {
          downloadFile({ file: result, fileName: 'schedule.csv' });
        } else {
          this.scheduleResult = result;
        }
      } catch (error) {
        console.error('Error fetching schedule:', error);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-section {
  max-width: fit-content;
}
</style>
